
import Vue from "vue";
import AppBar from "../../components/Admin/AppBar/AppBar.vue";
// @ts-ignore
import CustomLoading from "@/components/Commons/Loaders/Loading/index.vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
import { isEmpty, isNull } from "lodash";
import { mapActions, mapGetters } from "vuex";
// @ts-ignore
import { TypeLoading } from "@/interfaces/loading";
// @ts-ignore
import { Notification, MessageTypes } from "@/interfaces/proccess";
// @ts-ignore
import UserAgent from "@/plugins/navigator";
// @ts-ignore
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
import { MenuRoute, prepareMenu } from "./menu";

export default Vue.extend({
	name: "LayoutAdmin",
	props: {
		title: {
			type: String,
			default: "Create New Campaign",
		},
	},
	components: { AppBar, CustomLoading, SnackMessage },
	data: () => ({
		ready: false,
		show: true,
		profile: null,
		tabs: [],
		attrs: {
			class: "mb-6",
			boilerplate: true,
			elevation: 2,
		},
		userAgent: new UserAgent()
	}),
	created() {
		this.$nextTick(async () => {
			await this.setLoadingData(TypeLoading.loading);
			this.profile = await this.fetchProfile();

			const title: string = this.getAccount?.name;
			document.title = title;

			await this.setLookAndFeel();
			await this.handlePrepareMenu();
			await this.setLoadingData();
		});
	},
	mounted() {},
	computed: {
		...mapGetters("profile", ["account"]),
		...mapGetters("loading", ["isLoading"]),
		...mapGetters("internationalization", ["getSnack"]),

		getAccount() {
			return this.account;
		},

		getItems() {
			return this.tabs;
		},

		getProfile(): any {
			return this.profile;
		},
		getRoles(): Array<any> {
			const profile = this.getProfile;
			if (!profile) return [];
			return profile.roles || [];
		},
		getSuccess(): Notification {
			return {
				type: MessageTypes.SUCCESS,
				title: i18n.t("title-success"),
				message: i18n.t("account-success"),
				btn_text: i18n.t("continue"),
				to: "",
				show: true,
			} as Notification;
		},
	},
	methods: {
		...mapActions("loading", ["setLoadingData"]),
		async filterItems(data: any) {
			const roles: Array<any> = this.getRoles;
			const items: Array<MenuRoute> = data.filter((item: MenuRoute) => {
				item.open = false;

				if(this.isPlanningRappi(item)) return false;

				if (typeof item.children != "undefined") {
					item.children = item.children.filter((child: MenuRoute) => {
						child.open = false;
						return this.hasSome(roles, child.roles, "name");
					});
				}

				return this.hasSome(roles, item.roles, "name");
			});
			return items;
		},

		isPlanningRappi(item: { text: any }): Boolean {
			return (
				item.text == "menu.personsMenu.title" &&
				!isNull(this.getAccount.account_type) &&
				this.isAccountRappi()
			);
		},

		async setNotification(notification: Notification) {
			return await this.$store.dispatch(
				"proccess/setNotification",
				notification,
				{
					root: true,
				}
			);
		},

		async handlePrepareMenu() {
			this.tabs = await this.filterItems(prepareMenu());
		},

		async setLookAndFeel() {
			const styleSheets = Array.from(document.styleSheets).filter(
				(styleSheet) => {
					return (
						!styleSheet.href ||
						styleSheet.href.startsWith(window.location.origin)
					);
				}
			);
			for (let style of styleSheets) {
				if (style instanceof CSSStyleSheet && style.cssRules) {
					for (const rule of Object.entries(style.cssRules)) {
						if (rule[1]["selectorText"] == ":root") {
							//header_color_1
							rule[1]["style"].setProperty(
								"--header-1-color",
								this.getProfile.colors.header_color_1
							);
							rule[1]["style"].setProperty(
								"--v-primary-base",
								this.getProfile.colors.header_color_1
							);
							// rule[1]["style"].setProperty(
							// 	"--top-menu-1-color",
							// 	this.getProfile.colors.header_color_1
							// );
							rule[1]["style"].setProperty(
								"--header-1-darkest-color",
								this.getProfile.colors.header_color_1
							);

							// header_color_2
							rule[1]["style"].setProperty(
								"--header-2-color",
								this.getProfile.colors.header_color_2
							);

							// buttons_color
							rule[1]["style"].setProperty(
								"--button-color",
								this.getProfile.colors.buttons_color
							);
							rule[1]["style"].setProperty(
								"--v-secondary-base",
								this.getProfile.colors.buttons_color
							);

							// top_menu_color
							rule[1]["style"].setProperty(
								"--top-menu-1-color",
								this.getProfile.colors.top_menu_color_1
							);
							rule[1]["style"].setProperty(
								"--top-menu-2-color",
								this.getProfile.colors.top_menu_color_2
							);

							// link_color
							rule[1]["style"].setProperty(
								"--link-color",
								this.getProfile.colors.links_color
							);

							// icon_color
							rule[1]["style"].setProperty(
								"--icon-color",
								this.getProfile.colors.icons_color
							);

							// title_color
							rule[1]["style"].setProperty(
								"--title-color",
								this.getProfile.colors.titles_color
							);

							rule[1]["style"].setProperty(
								"--account_name_color",
								this.getProfile.colors.account_name_color
							);

							rule[1]["style"].setProperty(
								"--menu_item_color",
								this.getProfile.colors.menu_item_color
							);

							rule[1]["style"].setProperty(
								"--menu_item_hover_color",
								this.getProfile.colors.menu_item_hover_color
							);

							rule[1]["style"].setProperty(
								"--user_name_color",
								this.getProfile.colors.user_name_color
							);
							// loader-color
							// rule[1]["style"].setProperty(
							// 	"--loader-color",
							// 	this.getProfile.colors.icons_color
							// );

							// logo
							// rule[1]["style"].setProperty(
							// 	"--image-logo",
							// 	this.getProfile.logo
							// );
						}
					}
				}
			}
			this.ready = true;
		},

		hasSome(
			profileRoles: Array<any>,
			childRoles: Array<any>,
			key: string = ""
		) {
			return profileRoles.some(
				(rol) => childRoles.indexOf(isEmpty(key) ? rol : rol[key]) > -1
			);
		},
		isAccountRappi(){
			const value= "rappi_amplify";
			return this.getAccount.account_type===value;
		},
		async updateData(){
			await this.setLoadingData(TypeLoading.loading);
			this.profile = await this.fetchProfile();
			const title: string = this.getAccount?.name;
			document.title = title;
			await this.setLookAndFeel();
			await this.handlePrepareMenu();
			await this.setLoadingData();
		},

		async handleUpdate() {
			await this.updateData().then((r) => {
				this.setNotification(this.getSuccess);
			});
		},

		async fetchProfile() {
			return this.$store.dispatch("profile/fetchProfile");
		},
	},
	watch: {
	},
});
