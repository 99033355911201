
import Vue from "vue";
// @ts-ignore
import { mapActions, mapGetters } from "vuex";

export default Vue.extend({
	name: "GitVersion",
	props: {},
	components: {},
	data: () => ({
		version: {
			branch: "",
			current_version: "",
		},
	}),
	created() {
		this.$nextTick(async () => {
			this.version = await this.getVersion();
		});
	},
	mounted() {},
	computed: {
		...mapGetters("proccess", ["showBranch", "showVersion"]),
	},
	methods: {
		...mapActions("proccess", ["getVersion"]),
	},
});
