
import Vue from "vue";
export default Vue.extend({
	props: {
		item: {
			type: Object,
			required: true,
		},
		searchQuery: {
			type: String,
            default: ""
		},
	},
	computed: {
		highlightedId() {
			if (!this.searchQuery) return this.item.external_id;
			const regex = new RegExp(this.escapeRegExp(this.searchQuery), "gi");

			return this.item.external_id
				.toString()
				.replace(
					regex,
					(match: any) => `<span class="highlight">${match}</span>`
				);
		},
		highlightedText() {
			if (!this.searchQuery) return this.item.name;
			const regex = new RegExp(this.escapeRegExp(this.searchQuery), "gi");

			return this.item.name.replace(
				regex,
				(match: any) =>
					`<span class="font-weight-bold text-subtitle-2 highlight">${match}</span>`
			);
		},
	},
	methods: {
		escapeRegExp(string: string) {
			return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
		},
		handleClick() {
			this.$emit("click", this.item.id);
		},
	},
});
